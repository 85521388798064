import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { IoPersonRemove } from "react-icons/io5";

export const AssignedAb = () => {
  const [viewAbstract, setAbstracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [searchInput, setSearchInput] = useState(''); // Step 1: Add state for search input
  const [totalPage, setTotalPage] = useState(0);
  const [toPage, setToPage] = useState(0);
  const [fromPage, setFromPage] = useState(0);
  const [perPage, setperPage] = useState(0);

  // Fetch data from API
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((res) => {
      axios
        .get(`/api/get-assignedabstracts?page=${currentPage}&search=${searchInput}`)
        .then((res) => {
          if (res.status === 200) {
            setAbstracts(res.data.abstracts);
            setLastPage(res.data.pagination.last_page);
            setCurrentPage(res.data.pagination.current_page);
            setFromPage(res.data.pagination.from);
            setToPage(res.data.pagination.to);
            setTotalPage(res.data.pagination.total);
            setperPage(res.data.pagination.per_page);
            setLoading(false);
          }
        });
    });
  }, [currentPage, searchInput]);

  // Handle search input change
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const truncateTitle = (title, wordLimit) => {
    const words = title.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + " ....";
    }
    return title;
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to unassign this Abstract?")) {
      try {
        await axios.delete(`/api/unassign-abstract/${id}`);
        swal("Success", "Abstract unassigned successfully", "success");
        setAbstracts((prevState) => prevState.filter((item) => item.id !== id));
      } catch (error) {
        console.error("Error unassigning abstract:", error);
      }
    }
  };

  let display_Abstractsdata = "";
  if (loading) {
    return (
      <div className="text-center max-w-screen-xl max-h-screen-[72] mx-auto justify-center items-center ">
        <div role="status" className="mt-[20rem]">
          {/* Loading spinner */}
        </div>
      </div>
    );
  } else {
    display_Abstractsdata = viewAbstract.map((item, i) => {
      const serialNumber = (currentPage - 1) * perPage + (i + 1);
      return (
        <tr key={i}>
          <td className="pl-6 py-4 whitespace-nowrap text-start text-[12px]">
            <div className="text-[12px] text-gray-900">{serialNumber}</div>
          </td>
          <td className="pl-6 py-4 whitespace-nowrap text-start  text-[12px]">
            <div className="text-[12px] text-gray-900">NTBC2024AB-0{item.abstractpost.id}</div>
          </td>
          <td className="px-2 py-4 whitespace-nowrap text-start text-[12px] text-gray-500">
            <div className="text-start text-[12px]">
              {item.abstractpost ? truncateTitle(item.abstractpost.abstract_title, 10) : 'No Title'}
            </div>
          </td>
          <td className="px-2 py-4 whitespace-nowrap text-start  text-[12px] text-gray-500">
            {item.abstractpost ? item.abstractpost.abstract_thematic : 'N/A'}
          </td>
          <td className="px-2 py-4 whitespace-nowrap text-start text-xs">
            <div className="flex text-start text-xs">
              <div className="text-start text-xs">
                {item.user && `${item.user.firstname} ${item.user.lastname}`}
              </div>
            </div>
          </td>
          <td className="px-2 py-4 whitespace-nowrap text-start text-xs">
            <span
              className={`px-2 uppercase inline-flex text-xs leading-5 font-semibold rounded-full ${item.status === "approved" ? "bg-green-100 text-green-800" :
                  item.status === "approved-with-revision" ? "bg-yellow-500 text-yellow-900" :
                    item.status === "assigned" ? "bg-yellow-100 text-yellow-800" :
                      item.status === "not-approved" ? "bg-yellow-100 text-yellow-800" :
                        item.status === "recommended" ? "bg-green-100 text-green-800" :
                          item.status === "recommended-with-revision" ? "bg-yellow-100 text-yellow-800" : "bg-red-100 text-red-800"
                }`}
            >
              {item.status}
            </span>
          </td>
          <td className="flex justify-center space-x-0 items-center text-center px-1 py-4 whitespace-nowrap text-[12px] font-medium">
          <Link to={`/dashboard/edit_approved_status_ad/${item.abstractpost.id}`} className="text-indigo-600 px-2 hover:text-indigo-900">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="stroke-green-600 w-4 h-4 hover:stroke-green-800">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"/>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                          </svg>
                        </Link>
            <button onClick={() => handleDelete(item.id)} className="text-red-600 px-2 hover:text-red-900 ml-2 justify-center items-center text-center">
              <IoPersonRemove className="w-3 h-3 " />
            </button>
          </td>
        </tr>
      );
    });
  }

  return (
    <div className="flex mx-3 mt-2 flex-col">
      <div className="md:flex items-center justify-between mx-4 my-2 mt-4">
        <div className="flex  text-center">
          <h2 className="text-gray-600 mt-2 lg:mt-8 md:text-xl text-[12px] font-semibold text-center">
            Assigned Abstracts
          </h2>
        </div>
        <div className=" flex  items-end pt-2 sm:pt-4 md:pt-4 lg:pt-5 ">
          <input
            type="text"
            placeholder="Search Abstracts..."
            value={searchInput}
            onChange={handleSearch}
            className="border-2 border-gray-300 bg-white h-8 px-5 pr-16 rounded-lg text-[12px] focus:outline-none"
          />
        </div>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          {display_Abstractsdata.length > 0 ? (
            <div className="shadow md:overflow-hidden overflow-x-auto border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">S/N</th>
                    <th className="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                    <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Abstract Title</th>
                    <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Thematic</th>
                    <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Author</th>
                    <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {display_Abstractsdata}
                </tbody>
              </table>
              <div className="flex font-medium text-xs justify-center items-center space-x-4 m-2">
                <div>{` ${fromPage} - ${toPage} of ${totalPage} `}</div>
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className="bg-custom-green disabled:hidden bg-custom-dark-green rounded-sm p-1 text-white"
                >
                  Previous
                </button>
                <button
                  onClick={nextPage}
                  disabled={currentPage === lastPage}
                  className="bg-custom-green disabled:hidden bg-custom-dark-green rounded-sm p-1 text-white"
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-72">
              <p className="text-gray-500 text-lg">No abstracts available</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
